.root {
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}

.content {
  background: #fff;
  margin: 24px;
  padding: 24px;
}

.exit {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  color: #fff;
}

.exit:hover {
  cursor: pointer;
  background-color: #252a3d;
}

.title {
  color: #fff;
}
