.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select {
  width: 200px;
}

.styledTable table {
  margin-top: 24px;
  width: auto !important;
  min-width: unset !important;
}

.addButton {
  margin-left: 24px;
}

.url {
  width: 300px;
  margin-bottom: 0px !important;
  color: #1890ff !important;
}
