.styledTable table {
  margin-top: 24px;
  width: auto !important;
  min-width: unset !important;
}

.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addButton {
  margin-left: 24px;
}

.select {
  margin-left: 24px !important;
  width: 200px;
}

.icon svg {
  color: green;
}
