.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.steps {
  width: 550px;
  align-self: center;
}

.uploadContainer {
  width: 500px;
  align-self: center;
  margin-top: 24px;
}

.convertForm {
  margin-top: 24px;
  width: 500px;
  align-self: center;
}

.leaflet {
  width: 333px;
  height: 500px;
}
