.filterBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.findText {
  padding-right: 8px;
}

.cancelButton {
  margin-left: 24px;
}

.input {
  max-width: 300px;
}
